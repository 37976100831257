.content-cert {
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  /* height: 100vh; */
  /* background: #252525; */
}

/* .particle-container {
  position: relative;
  width: 100%;
  height: 100%;
} */

/* Style particles (position them absolutely within the particle container) */
#particles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
