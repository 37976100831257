.mainContainer {
  position: relative;
}

.headerSection {
  position: relative; /* Ensures the header stays in the document flow */
  z-index: 2; /* Sets the stacking order to be on top */
}

.mainContentSection {
  /* Adjust the margin as needed */
  position: relative; /* Ensures the main content stays in the document flow */
  z-index: 1; /* Sets the stacking order to be below the header */
}

.aboutmeSection {
  /* Adjust the margin as needed */
  position: relative; /* Ensures the main content stays in the document flow */
  z-index: 1; /* Sets the stacking order to be below the header */
}

.skillsSection {
  /* Adjust the margin as needed */
  position: relative; /* Ensures the main content stays in the document flow */
  z-index: 1; /* Sets the stacking order to be below the header */
}

.projectsSection {
  /* Adjust the margin as needed */
  position: relative; /* Ensures the main content stays in the document flow */
  z-index: 1; /* Sets the stacking order to be below the header */
}

.achievementSection {
  /* Adjust the margin as needed */
  position: relative; /* Ensures the main content stays in the document flow */
  z-index: 1; /* Sets the stacking order to be below the header */
}

.contactmeSection {
  /* Adjust the margin as needed */
  position: relative; /* Ensures the main content stays in the document flow */
  z-index: 1; /* Sets the stacking order to be below the header */
}

.footerSection {
  /* Adjust the margin as needed */
  position: relative; /* Ensures the main content stays in the document flow */
  z-index: 1; /* Sets the stacking order to be below the header */
}
